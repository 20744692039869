import React from 'react'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <div>
    <h1>Страница не найдена...</h1>
    <p><Link to="/">Перейти на главную</Link></p>
  </div>
)

export default NotFoundPage
